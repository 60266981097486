import React, { FormEvent, useContext } from 'react';
import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import { IUser } from '../models/user';
import { updateUser } from '../service/user.service';
import { useDispatch } from 'react-redux';
import { clearFormErrors } from '../actions/formError.actions';
import UserForm from '../components/forms/UserForm';
import { RestClientContext } from '../context';

function Register({ id }: { id: string }) {
    const restClient = useContext(RestClientContext)!;
    const dispatch = useDispatch();
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<IUser>({
        id,
        region: '',
        addressStreet: '',
        addressHouseNumber: '',
        addressRoomNumber: '',
        addressZip: '',
        addressLocation: '',
        phoneNumber: '',
        mobileNumber: '',
        replacementGivenName: '',
        replacementSurname: '',
        replacementRegion: '',
    });

    const handleSubmit = async (event: FormEvent): Promise<void> => {
        event.preventDefault();
        dispatch(clearFormErrors());

        setProcessing(true);
        try {
            await updateUser(restClient, user);
            localStorage.clear();
        } catch (ex) {
            setProcessing(false);
            return;
        }
        setProcessing(false);

        window.location.reload();
    };

    return (
        <Container maxWidth="sm">
            <Grid container style={{ marginTop: '10%', marginBottom: 50 }}>
                <Grid item xs={12}>
                    <Paper variant="outlined" style={{ padding: 15 }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" gutterBottom>Registrierung abschließen</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <UserForm user={user} onChange={setUser} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        type="submit"
                                        style={{ marginTop: 20 }}
                                        disabled={processing}
                                    >
                                        Speichern
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Register;
