import React from 'react';
import {Breadcrumbs, Button, Container, Divider, Grid, Link as LinkBread, Paper, Typography} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

function Management({ roles }: { roles: string[] }) {
    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <LinkBread
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Dashboard
                                    </LinkBread>
                                    <Typography color="text.primary">Verwaltung</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Verwaltung</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography variant="h5" gutterBottom>Logistikverwaltung</Typography>
                        <Divider />
                    </Grid>
                    {roles.includes('verwaltung_nutzer') && (
                        <React.Fragment>
                            <Grid item xs={12} style={{ marginTop: 20 }}>
                                <Typography variant="h5" gutterBottom>Nutzerverwaltung</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item>
                                        <a href="https://id-us.urban-scope.de/admin" rel="noreferrer" target="_blank">
                                            <Button variant="outlined" size="large">KeyCloak Konsole</Button>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Paper>
        </Container>
    );
}

export default Management;
