import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import store from './store';
import './index.css';
import { AppConfig } from './models/config';
import axios from 'axios';
import { AppConfigContext, RestClientContext } from './context';
import { onError } from './rest/interceptor';
import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';

function ConfigProvider({ children }: { children: React.JSX.Element }) {
    const [config, setConfig] = useState<AppConfig | null>(null);
    const [keyCloakConfig, setKeyCloakConfig] = useState<any>();

    useEffect(() => {
        axios.get('/keycloak.json')
            .then(res => {
                setKeyCloakConfig(res.data);
            });
        axios.get('/config.json')
            .then(res => {
                const data = res.data;

                if (process.env.NODE_ENV === 'development') {
                    setConfig({ ...data, endpoint: 'https://localhost:5246' });
                    return;
                }

                setConfig(data);
            });
    }, []);

    if (!config || !keyCloakConfig) {
        return <p>Loading Config ...</p>;
    }

    const oidcConfig = {
        authority: `${keyCloakConfig['auth-server-url']}/realms/${keyCloakConfig['realm']}`,
        client_id: keyCloakConfig['resource'],
        redirect_uri: window.location.href,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    };

    return (
        <AppConfigContext.Provider value={config}>
            <AuthProvider {...oidcConfig}>
                {children}
            </AuthProvider>
        </AppConfigContext.Provider>
    );
}

function RestClientProvider({ children }: { children: React.JSX.Element }) {
    const config = useContext(AppConfigContext)!;
    const client = useMemo(() => {
        const rest = axios.create({
            baseURL: config.endpoint,
            timeout: 30000,
            maxRedirects: 0,
        });

        rest.interceptors.response.use(response => response, err => onError(err));

        return rest;
    }, [config]);

    return (
        <RestClientContext.Provider value={client}>
            {children}
        </RestClientContext.Provider>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ConfigProvider>
                    <RestClientProvider>
                        <App />
                    </RestClientProvider>
                </ConfigProvider>
            </LocalizationProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
