import { useAuth } from 'react-oidc-context';
import React from 'react';

function Logout() {
    const auth = useAuth();

    React.useEffect(() => {
        auth.signoutRedirect()
            .catch(console.error);
    }, []);

    return null;
}

export default Logout;
