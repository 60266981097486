import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './screens/Dashboard';
import MiniDrawer from './components/Menu';
import Logout from './screens/Logout';
import Articles from './screens/Articles';
import Projects from './screens/projects/Projects';
import ConfirmPrompt from './components/dialogs/ConfirmPrompt';
import { CircularProgress, Grid, ThemeProvider } from '@mui/material';
import theme from './theme';
import { useSelector } from 'react-redux';
import Register from './screens/Register';
import { IUser } from './models/user';
import Profile from './screens/Profile';
import ProjectCart from './screens/projects/ProjectCart';
import Footer from './components/Footer';
import ProgramOverview from './screens/ProgramOverview';
import { RestClientContext } from './context';
import CategoryOverview from './screens/CategoryOverview';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import store from './store';
import { loadCart } from './actions/cart.actions';
import { getUser } from './service/user.service';
import Management from './screens/Management';

function App() {
    const { darkMode } = useSelector((store: any) => store.uiReducer);
    const [userInfo, setUserInfo] = React.useState<any>();
    const [user, setUser] = React.useState<IUser>();
    const restClient = useContext(RestClientContext)!;
    const [hasTriedSignIn, setHasTriedSignIn] = useState<boolean>(false);
    const [ready, setReady] = useState<boolean>(false);
    const auth = useAuth();

    // automatically sign-in
    useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignIn) {
            auth.signinRedirect();
            setHasTriedSignIn(true);
        }

        if (auth.isAuthenticated && auth.user) {

            restClient.defaults.headers.common['Authorization'] = `Bearer ${auth.user.access_token}`;

            if (typeof window !== 'undefined' && window.location.href.includes('?state=')) {
                window.history.pushState({}, document.title, '/');
            }

            const profile: any = auth.user.profile;

            if (profile.realm_access.roles.includes('registered')) {
                getUser(restClient)
                    .then(user => setUser({ ...user, email: profile.email }))
                    .catch(console.error);
            }
            store.dispatch((dispatch:any) => loadCart(restClient, dispatch));

            setUserInfo(auth.user.profile);
            setReady(true);
        }
    }, [auth, hasTriedSignIn]);

    if (auth.isLoading || !auth.isAuthenticated || !ready) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>
        );
    }

    if (!userInfo.realm_access.roles.includes('registered')) {
        return (
            <ThemeProvider theme={theme(false)}>
                <Register id={userInfo.sub} />
            </ThemeProvider>
        );
    }

    if (!user) {
        return null;
    }

    return (
        <Router>
            <ThemeProvider theme={theme(darkMode, user.primaryColor)}>
                <MiniDrawer user={user} roles={userInfo.realm_access.roles}>
                    <ConfirmPrompt />
                    <Switch>
                        <Route exact path="/">
                            <Dashboard name={user.name} />
                        </Route>
                        <Route path="/profil">
                            <Profile user={user} />
                        </Route>
                        <Route path="/artikel">
                            <Articles />
                        </Route>
                        <Route path="/markenübersicht">
                            <ProgramOverview />
                        </Route>
                        <Route path="/kategorien">
                            <CategoryOverview />
                        </Route>
                        <Route exact path="/projekte">
                            <Projects user={user} name={user.name} />
                        </Route>
                        <Route path="/projekte/:id">
                            <ProjectCart />
                        </Route>
                        <Route path="/verwaltung">
                            <Management roles={userInfo.realm_access.roles} />
                        </Route>
                        <Route path="/logout">
                            <Logout />
                        </Route>
                        <Route>
                            Diese Seite wurde nicht gefunden.
                        </Route>
                    </Switch>
                </MiniDrawer>

                <Footer />
            </ThemeProvider>
        </Router>
    );
}

export default App;
